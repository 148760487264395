export const SELECT_OPTIONS = {
    ua: {
        label: 'UA',
        code: "+38 ",
        mask: "(999) 999-99-99"
    },
    he: {
        label: 'HE',
        code: "+972 ",
        mask: "(999) 999-99-99",
    },
    md: {
        label: 'MD',
        code: "+373 ",
        mask: "9999-9999",
    },
    ro: {
        label: 'RO',
        code: "+40 ",
        mask: "-99-999-9999",
    },
    pl: {
        label: 'PL',
        code: "+48 ",
        mask: "(999) 999-999",
    },
    bg: {
        label: 'BG',
        code: "+359 ",
        mask: "(999) 999-999"
    },
    hu: {
        label: 'HU',
        code: "+36 ",
        mask: "(999) 999-999",
    },
    de: {
        label: 'DE',
        code: "+49 ",
        mask: "(999) 999-99-99",
    },
    sk: {
        label: 'SK',
        code: "+421 ",
        mask: "(999) 999-9999",
    },
    lv: {
        label: 'LV',
        code: "+371 ",
        mask: "9999-9999",
    },
    lt: {
        label: 'LT',
        code: "+370 ",
        mask: "(999) 99-9999",
    },
    et: {
        label: 'ET',
        code: "+372 ",
        mask: "9999-9999",
    },
    at: {
        label: 'AT',
        code: "+43 ",
        mask: "999-999-999",
    },
    ke:{
        label: 'KE',
        code: "+254 ",
        mask: "(999) 999-999",
    }

}
// ru: {
//     label: 'RU',
//     code: "+7 ",
//     mask:"(999) 999-99-99",
// }
export const  parsePhoneNumber=(phoneNumber)=> {
    for (const country in SELECT_OPTIONS) {
        const countryInfo = SELECT_OPTIONS[country];
        const code = countryInfo.code;

        if (phoneNumber.startsWith(code)) {
            const phoneNumberWithoutCode = phoneNumber.slice(code.length);
            return {
                countryCode: countryInfo.label.toLowerCase(),
                phoneNumberWithoutCode: phoneNumberWithoutCode.trim(),
                mask: countryInfo.mask
            };
        }
    }

    // If no matching country code is found, return the original phone number
    return {
        countryCode: 'ua',
        phoneNumberWithoutCode: phoneNumber.trim(),
        mask: SELECT_OPTIONS.ua.mask
    };
}

export function validatePhoneNumber(countryCode, phoneNumber) {
    // Получаем данные по стране из SELECT_OPTIONS
    const countryData = SELECT_OPTIONS[countryCode];

    // Если страны с таким кодом нет, считаем номер некорректным
    if (!countryData) {
        return false;
    }

    // Формируем регулярное выражение на основе маски
    const regexPattern = countryData.mask
        .replace(/\(/g, "\\(")
        .replace(/\)/g, "\\)")
        .replace(/-/g, "\\-")
        .replace(/\+/g, "\\+")
        .replace(/9/g, "\\d");

    const regex = new RegExp("^" + regexPattern + "$");

    // Проверяем соответствие номера маске
    return regex.test(phoneNumber);
}