import React from 'react';
import parse from 'html-react-parser';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    helpArticleModalSelected,
    helpArticleSendCommentMailSelected
} from "../../../redux/helpl-aricle/help-article-selector";
import Modal from "../../ui/Modal";
import {helpArticleGetByIdToggleModalAC} from "../../../redux/helpl-aricle/help-article-action";
import Loader from "../../Loader";
import Comment from "./Comment";
import {helpArticleSendCommentMailThunk} from "../../../redux/helpl-aricle/help-article-thunk";

const HelpArticleModal = () => {
    const {t} = useTranslation();
    const help_article_modal = useSelector(helpArticleModalSelected);
    const send_comment_mail = useSelector(helpArticleSendCommentMailSelected)
    const dispatch = useDispatch();
    const onClose = () => {
        dispatch(helpArticleGetByIdToggleModalAC({
            is_open: false,
            content: null,
            isError: null,
            error: null,
            isLoading: false
        }))
    }
    const handlerSubmit = async (value, resetFieldCb) => {
        let text = `Комментрарий пользователя к статье №. ${help_article_modal.content.id}. 
        ${value}
        `
        let res = await dispatch(helpArticleSendCommentMailThunk(text))
        if (res && resetFieldCb) resetFieldCb()
        console.log(value);

    }
    if (help_article_modal.isLoading || send_comment_mail.isLoading) return <Loader/>
    if (!help_article_modal.is_open) return null
    return (
        <div>
            <Modal title={help_article_modal.content.title} onClose={onClose}>
                <div className="help-article-content">
                    {parse(`${help_article_modal.content.description}`)}
                    {help_article_modal.content.url && <a href={help_article_modal.content.url} target={"_blank"} className="">{t("open_help_article")}</a>}
                </div>
                {(help_article_modal.content?.related_articles && Array.isArray(help_article_modal.content?.related_articles) && !!help_article_modal.content?.related_articles?.length)
                    && <div className={"mt-2 mb-2"}>
                        <p className={"comment-accordion-toggle "}>{t("related_articles")}</p>
                        <ul style={{paddingLeft: '15px'}}>
                            {help_article_modal.content?.related_articles?.map(item => {
                                return <li><a href={item?.url} target={"_blank"}>{item?.title}</a></li>
                            })}
                        </ul>
                    </div>
                }
                <Comment handlerSubmit={handlerSubmit}/>
            </Modal>
        </div>
    );
};

export default HelpArticleModal;