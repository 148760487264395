import {
    accountMessageChannelsConfirmAC, accountNetworkLoginAC,
    authLookupAC, authNetworkSocialLoginAC,
    initialVerificationUserAC,
    loginUserAC,
    registerUserAC
} from "./auth-action";
import {clearAllStorage, LS_CLIENT_TOKEN, LS_FIRM, LS_USER_TOKEN} from "../../consts/localStorageNaming";
import {TAB_HASH_IDS} from "../../routing_hash";
import {toast} from "react-toastify";
import {toggleLoader} from "../app-reducer";
import {AuthAPI} from "../../api/AuthAPI";
import i18next from 'i18next';
import {createFormData, isEmail} from "../../utils/common";
import axios from "axios";
import AuthSocialAPI from "../../api/AuthSocialAPI";
import md5 from "md5";
import {AccountAPI} from "../../api/AccountAPI";
import {OauthFacebookApi, OauthGoogleApi} from "../../api/OauthGoogleApi";
import {messageChannelsThunk} from "../message-channels-thunk";


async function hashDataSocial({id, network, email = null, name = null}) {
    try {
        const getHash = await AuthSocialAPI.getNetworkLoginHash();
        let hash = getHash.hash;
        let api_data = {
            network: network,
            // data: {
            hash: md5(id + hash),
            id: id
            // }
        }
        if (email) {
            api_data = {
                ...api_data,
                // data: {
                //     ...api_data.data,
                email: email
                // }
            }
        }
        if (name) {
            api_data = {
                ...api_data,
                // data: {
                //     ...api_data.data,
                name: name
                // }
            }
        }
        return Promise.resolve(api_data)
    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        return Promise.reject(msg)
    }

}


/**
 * initialVerificationUserClear  очищаем поля и клиент не авторизирован в системе.
 */
function initialVerificationUserClear(error, dispatch) {
    clearAllStorage();
    dispatch(initialVerificationUserAC({
        isLoading: false, isError: false, error: error, user: null
    }))
}

/**
 * authThunkAccountAccess Проверить есть ли пользователь .
 *
 * @param {object}  api_data - key, values
 * @returns  {function | null} onSwitchTabRegister- переключить форму на регисрацию
 * @returns  {function | null} onSwitchTabAuth- переключить форму на авторизацию
 * @returns  {undefined}   -
 */
export const authThunkAccountAccess = (api_data, onSwitchTabRegister = null, onSwitchTabAuth = null) => async dispatch => {
    try {
        const formData = createFormData(api_data)
        dispatch(authLookupAC({isLoading: true}))
        const response = await AuthAPI.authLookup(formData);
        console.log('rs', response)
        if (response.hasOwnProperty('error')) {
            toast.error(response.error)
            dispatch(authLookupAC({isLoading: false, isError: true, error: response.error, data: null}));

            return new Promise((_, req)=> req(response))
        }
        const isFindUser = response && response.lookup && response.user;
        const isShowMessageSendPassword = (response && response.lookup && response.passwordSent);
        if (isShowMessageSendPassword) {
            toast.success(i18next.t('message.password_send'))
        }
        if (isFindUser && !isShowMessageSendPassword && api_data.send_password) {
            toast.error(i18next.t('message.not_send_password'))
        }

        if (isFindUser && onSwitchTabAuth) {
            dispatch(authLookupAC({isLoading: false, data: null}))
            onSwitchTabAuth()
            return
        }
        if (!isFindUser && onSwitchTabRegister) {
            let isRadioTypeAuthEmail = isEmail(api_data.login)
            dispatch(registerUserAC({
                email: isRadioTypeAuthEmail ? api_data.login : '', phone: !isRadioTypeAuthEmail ? api_data.login : '',
            }));
            if (onSwitchTabRegister) {
                dispatch(authLookupAC({isLoading: false, data: null}))
                setTimeout(() => {
                    onSwitchTabRegister();
                }, [])
                return
            }

        }
        dispatch(authLookupAC({isLoading: false, data: response}))
        return  new Promise(res=> res(true))
    } catch (e) {
        let error_msg = e.hasOwnProperty('error') ? e.error : e.message
        toast.error(error_msg)
        dispatch(authLookupAC({isLoading: false, isError: true, error: null, data: null}));
        return new Promise((_, req)=> req(e))
    }
}
/**
 * authThunkLogin Авторизация пользователя.
 *
 * @param {object}  api_auth -
 * @returns  {string} email  -
 * @returns  {string} password  -
 */
export const authThunkLogin = (api_auth) => async dispatch => {
    try {
        dispatch(loginUserAC({isLoading: true}))
        const response = await AuthAPI.login(api_auth);
        if (response.hasOwnProperty('error')) {
            toast.error(response.error)
            dispatch(loginUserAC({isLoading: false, isError: true, error: response.error}));
            return
        }

        dispatch(initialVerificationUserAC({isLoading: true}));
        let firm = response.data.hasOwnProperty('auth_data') ? response.data.auth_data.firm : response.data.firm;
        localStorage.setItem(LS_FIRM, firm);
        dispatch(loginUserAC({isLoading: false, isError: false, error: null, email: '', phone: ''}));
        dispatch(authLookupAC({isLoading: false, data: null, error: null, isError: false}))
        dispatch(authThunkInitialVerificationUser(firm))
        window.location.hash = TAB_HASH_IDS.cabinet;


    } catch (e) {
        dispatch(initialVerificationUserAC({isLoading: false}));
        toast.error(i18next.t("errors.login"))
        dispatch(loginUserAC({isLoading: false, isError: true, error: i18next.t("errors.login"), auth_lookup: null}));

    }
}
/** authThunkUserAuthenticatedAndRedirect. Верификация пользователя и проверка на доступность страницы
 * .
 *
 * @param {string | null} firm -
 * @param {string | null}   switchHashIsAuth -   hash url redirect
 * @param {string | null}   switchHashNotAuth -  hash url redirect
 * @returns  {}  -
 */
export const authThunkUserAuthenticatedAndRedirect = (firmParam, switchHashIsAuth = null, switchHashNotAuth = null) => async dispatch => {
    try {
        //
        const response_auth = await AuthAPI.verification(firmParam || localStorage.getItem(LS_FIRM));

        if (response_auth.hasOwnProperty('error')) {
            initialVerificationUserClear(response_auth.error, dispatch);
            return;
        }
        let {token, firm, isAuthed, hasClients} = response_auth;
        let isAuthorized = isAuthed && token;
        if (!isAuthorized) {
            initialVerificationUserClear(null, dispatch)
            if (switchHashNotAuth) {
                window.location.hash = switchHashNotAuth;
            }

            return;
        }
        localStorage.setItem(LS_FIRM, firm);
        if (switchHashIsAuth) {
            await dispatch(authThunkInitialVerificationUser(firm));
            window.location = '/#' + switchHashIsAuth;
        }

    } catch (e) {
        dispatch(authThunkLogoutSystem());
        toast.error(e.message)
    }
}

/**
 * authThunkInitialVerificationUser. Проверям на авторизацию и получаем данные о пользователе
 * @param {string | null}  _firm - id firm
 */
export const authThunkInitialVerificationUser = (_firm) => async (dispatch) => {

    dispatch(initialVerificationUserAC({
        isLoading: true,
    }));
    try {
        //
        const response_auth = await AuthAPI.verification(_firm);

        dispatch(initialVerificationUserAC({
            settings: {telegram: response_auth.telegram || null, viber: response_auth.viber || null}
        }));
        if (response_auth.hasOwnProperty('error')) {
            initialVerificationUserClear(response_auth.error, dispatch);
            return;
        }
        let {token, firm, isAuthed, hasClients, firms = []} = response_auth;
        let isAuthorized = isAuthed && token;
        if (!isAuthorized) {
            initialVerificationUserClear(null, dispatch)
            return;
        }
        const response_user = await AuthAPI.getUser(token);
        if (response_user.hasOwnProperty('error')) {
            toast.error(response_user.error)
            initialVerificationUserClear(response_user.error, dispatch)
            return;
        }
        localStorage.setItem(LS_CLIENT_TOKEN, response_user.client_token || '');
        localStorage.setItem(LS_USER_TOKEN, token || '');
        localStorage.setItem(LS_FIRM, firm || '');

        let isManager = response_user.hasOwnProperty('client') && !response_user.client;
        let user_data = response_user;
        if (isManager) {
            let user_id = response_user.auth_client || response_auth.user_id || null;
            const response_manager = await AuthAPI.getAuthDataForManager({user_id});
            if (response_manager.hasOwnProperty('error')) {
                toast.error(response_manager.error)
                initialVerificationUserClear(response_manager.error, dispatch)
                return;
            }
            user_data = response_manager;
        }
        dispatch(initialVerificationUserAC({
            isLoading: false, isError: false, error: null, user: user_data
        }));
    } catch (e) {
        console.log('error', e)
        toast.error(e.message)

    }
}

export const authThunkLogoutSystem = () => async dispatch => {
    dispatch(initialVerificationUserAC({
        isLoading: true,
    }));
    try {
        await AuthAPI.logout();
        initialVerificationUserClear(null, dispatch);
        window.location.hash = TAB_HASH_IDS.home;
    } catch (e) {
        toast.error(e.message)

    }
}

/**
 * authThunkResetPassword сброс пароля.
 *
 * @param {string}  client_id - user client
 * @returns  {string} login   - email or phone
 */
export const authThunkResetPassword = ({client_id = "", login}) => async dispatch => {
    try {
        dispatch(toggleLoader(true));
        const response = await AuthAPI.resetPassword({client_id, login});

        if (response.hasOwnProperty('error')) {
            toast.error(response.error)
        }
        if (response.message) {
            toast.info(response.message)
        }
        dispatch(toggleLoader(false));

    } catch (e) {
        dispatch(toggleLoader(false));
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg)
    }
}
/**
 * authThunkRegisterCheckUser.
 *
 * @returns  {}  -
 */

export const authThunkRegisterCheckUser = (api_data) => async dispatch => {
    try {
        dispatch(registerUserAC({isLoading: true}))
        const response = await AuthAPI.registration({formData: api_data});

        if (response.hasOwnProperty('error')) {
            dispatch(registerUserAC({isLoading: false, isError: true, error: response.error}))
            toast.error(response.error);
            return
        }
        if (response.result) {
            dispatch(authLookupAC({isLoading: false, isError: false, error: null, data: null}));
            dispatch(registerUserAC({isSuccessful: true, isLoading: false}));
            // toast.success('Ok');
            return
        }
        dispatch(registerUserAC({isSuccessful: false, isLoading: false}));
    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg)
    }
}


/**
 * accountUpdateUserDataThunk. Обновление личных данных
 * api_data
 * @param {object} api_data -
 * @param {string} api_data.email -
 * @param {string} api_data.phone -
 * @param {string} api_data.name -
 * @param {number} api_data.id -
 */


export const accountUpdateUserDataThunk = (api_data) => async dispatch => {
    try {
        dispatch(toggleLoader(true))
        const update_response = await AuthAPI.updateUserData({...api_data, type_input: "part", active: 1});
        if (update_response.hasOwnProperty('error')) {
            toast.error(update_response.error);
            dispatch(toggleLoader(false))
            return
        }
        dispatch(authThunkInitialVerificationUser());
        dispatch(messageChannelsThunk());
        dispatch(toggleLoader(false))
    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg)
    }
}

/**
 * networkLoginSystem общий метод для логина/реги в систему через соц.сети.
 *
 * @param {object}  api_data-
 * @param {function} dispatch  -
 */
async function networkLoginSystem(api_data, dispatch) {

    try {
        const form_data = createFormData(api_data)
        const response_network_login = await AuthSocialAPI.networkLogin(form_data);
        if (response_network_login.hasOwnProperty('error')) {
            dispatch(toggleLoader(false))
            toast.error(response_network_login.error);
            return
        }
        if (response_network_login.state.state === "error") {
            dispatch(toggleLoader(false))
            dispatch(authNetworkSocialLoginAC({
                response_data: null,
                form_data: null, modal: {
                    isOpen: false, isShortPassword: false, isSendEmail: false
                }
            }))
            toast.error(response_network_login.state.text);
            return
        }
        if (response_network_login.state.state === "need_data") {
            let isPassword = response_network_login.state.fields.includes('once_password')
            let isEmail = response_network_login.state.fields.includes('email')
            dispatch(toggleLoader(false))
            dispatch(authNetworkSocialLoginAC({
                response_data: response_network_login,
                form_data: api_data, modal: {
                    isOpen: true, isShortPassword: isPassword, isSendEmail: isEmail
                }
            }))
            if (isPassword) {
                let send_channel = response_network_login.sent_to_message_channel? response_network_login.sent_to_message_channel : response_network_login.lookup;
                toast.info(`${i18next.t("message.password_send")} ${send_channel}`);
            }
            return
        }
        if (response_network_login.hasOwnProperty('state') && response_network_login.state.state === 'ok') {
            let firm_id = response_network_login.state.firm_id;
            if (firm_id) {
                localStorage.setItem(LS_FIRM, firm_id)
            }
            dispatch(authNetworkSocialLoginAC({
                response_data: null,
                form_data: null, modal: {
                    isOpen: false, isShortPassword: false, isSendEmail: false
                }
            }))
            dispatch(authLookupAC({isLoading: false, data: null, error: null, isError: false}))
            window.location.hash = TAB_HASH_IDS.cabinet;
            dispatch(authThunkInitialVerificationUser(firm_id));
            // window.location.reload();
        }
        dispatch(toggleLoader(false))
    } catch (e) {
        return Promise.reject(e)
    }

}


export const confirmNetworkSocialLoginSystem = (api_data) => async dispatch => {
    try {
        dispatch(toggleLoader(true))
        await networkLoginSystem(api_data, dispatch)
    } catch (e) {
        dispatch(toggleLoader(false))
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg)
    }
}
export const googleLoginThunk = (access_token) => async dispatch => {
    try {
        dispatch(toggleLoader(true))
        const user_info = await OauthGoogleApi.userInfo(access_token);
        const hash_data = await hashDataSocial({
            id: user_info.sub,
            email: user_info.email,
            name: user_info.name,
            network: "google"

        })
        await networkLoginSystem({
            ...hash_data,
            id: user_info.sub
        }, dispatch)
    } catch (e) {
        dispatch(toggleLoader(false))
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg)
    }
}
export const facebookGetEmailUserForLoginThunk = (api_data) => async dispatch => {
    try {
        dispatch(toggleLoader(true))
        await networkLoginSystem(api_data, dispatch)
    } catch (e) {
        dispatch(toggleLoader(false))
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg)
    }
}
// access_token
export const facebookLoginThunk = (user_info) => async dispatch => {
    try {
        dispatch(toggleLoader(true))
        // const user_info = await OauthFacebookApi.userInfo(access_token);
        const hash_data = await hashDataSocial({
            id: user_info.id,
            email: user_info.hasOwnProperty('email') ? user_info.email : null,
            name: user_info.name,
            network: "fb"

        })
        await networkLoginSystem({
            ...hash_data,
            id: user_info.id
        }, dispatch)
    } catch (e) {
        dispatch(toggleLoader(false))
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg)
    }
}


/**
 * confirmMessageChannelEmailOrPhone. подтвердить email or phone и обновить данные пользователя
 *
 * @param {object} api_data -
 * @param {string} api_data.value -  email or phone
 * @returns  {number} api_data.send_password - 0/1 отпарвить пароль
 * @returns  {string} api_data.message_channel - key чат-бот
 * @returns  {string} api_data.password - короткий пароль
 */
export const confirmMessageChannelEmailOrPhone = (api_data) => async dispatch => {
    try {
        dispatch(toggleLoader(true));
        const form_data = createFormData(api_data)
        const response = await AccountAPI.confirmationMessageChannel(form_data);
        if (response && response.hasOwnProperty('error')) {
            dispatch(toggleLoader(false))
            toast.error(response.error);
            return
        }
        if (response.hasOwnProperty('passwordValid')) {
            if (response.passwordValid) {
                dispatch(accountMessageChannelsConfirmAC({
                    isOpen: false,
                    form_data: null
                }))
                dispatch(authThunkInitialVerificationUser(localStorage.getItem(LS_FIRM)));
            } else if (!response.passwordValid) {
                toast.error(i18next.t('no_correct_password'))
            }
            dispatch(toggleLoader(false))
            return
        }
        let send_channel = response.lookup === 'email' ? 'email' : response.lookup === 'phone' ?
            response.message_channel : ''
        let isSendPassword = response && response.lookup && response.passwordSent;
        if (api_data.send_password && isSendPassword) {
            toast.success(`${i18next.t('message.password_send')} ${send_channel}`)
            dispatch(accountMessageChannelsConfirmAC({
                isOpen: true,
                form_data: {
                    ...api_data,
                    ...response
                }
            }))
        }
        if (api_data.send_password && !isSendPassword) {
            toast.error(i18next.t('message.not_send_password'))
        }
        dispatch(toggleLoader(false))
    } catch (e) {
        dispatch(toggleLoader(false))
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg)
    }
}

export const networkLoginUserThunk = () => async dispatch => {
    try {
        dispatch(accountNetworkLoginAC({isLoading: true, isError: false, error: null}))
        const response_network = await AuthSocialAPI.getNetworkLogin();
        if (response_network.hasOwnProperty('error')) {
            dispatch(accountNetworkLoginAC({
                isLoading: false,
                isError: true,
                error: response_network.error,
                list: null
            }))
            toast.error(response_network.error);
            return
        }
        dispatch(accountNetworkLoginAC({isLoading: false, isError: false, error: null, list: response_network.list}))


    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(accountNetworkLoginAC({isLoading: false, isError: true, error: msg, list: null}))

        toast.error(msg)
    }
}

/**
 * connectedNetworkSocial привязать соцсеть авторизованного пользователя и получить обновленый список.
 */
async function connectedNetworkSocial(api_data, dispatch) {
    const form_data = createFormData(api_data);
    const response_connect_network = await AuthSocialAPI.networkLogin(form_data);
    if (response_connect_network.hasOwnProperty('error')) {
        dispatch(accountNetworkLoginAC({isLoading: false, isError: true}))
        toast.error(response_connect_network.error);
        return
    }
    dispatch(networkLoginUserThunk())
}

/**
 * accountConnectedGoogle  привязать соцсеть google авторизованного пользователя.
 * @param {string} access_token - return provider google
 */
export const accountConnectedGoogle = (access_token) => async (dispatch, getState) => {
    try {
        dispatch(accountNetworkLoginAC({isLoading: true}))
        let userInfo = await OauthGoogleApi.userInfo(access_token)
        let data_api = await hashDataSocial({
            id: userInfo.sub,
            network: "google"
        });
        await connectedNetworkSocial(data_api, dispatch)

    } catch (e) {
        dispatch(accountNetworkLoginAC({isLoading: false}))
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg);
    }
}
/**
 * accountConnectedGoogle  привязать соцсеть google авторизованного пользователя.
 * @param {object} data_api -
 * @param {string} data_api.id - return provider fb id
 * @param {string} data_api.network - fb
 */
export const accountConnectedFacebook = (data_api) => async (dispatch) => {
    try {
        dispatch(accountNetworkLoginAC({isLoading: true}))
        const hash_data = await hashDataSocial(data_api)
        await connectedNetworkSocial(hash_data, dispatch)
    } catch (e) {
        dispatch(accountNetworkLoginAC({isLoading: false}))
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg);
    }
}
/**
 * deleteNetworkLoginSocial удалить привязку соцсети авторизованного пользователя.
 *
 * @param {object}  api_data
 * @param {string} api_data.id -
 * @param {string} api_data.network - google or fb
 */
export const deleteNetworkLoginSocial = (api_data) => async dispatch => {
    try {
        dispatch(accountNetworkLoginAC({isLoading: true}))
        const response_delete = await AuthSocialAPI.deleteNetworkLogin(api_data);
        if (response_delete.hasOwnProperty('error')) {
            dispatch(accountNetworkLoginAC({isLoading: false, isError: true}))
            toast.error(response_delete.error);
            return
        }
        dispatch(networkLoginUserThunk());
    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg);
    }
}