import React, {useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import IconTelegram from "../../../assets/Icons/IconTelegram";
import IconViber from "../../../assets/Icons/IconViber";
import IconWA from "../../../assets/Icons/IconWA";
import {filterObjectByKeys, unFilterObjectByKeys} from "../../../utils/common";
import QRCode from "react-qr-code";
import {messageChannelsSelected} from "../../../redux/auth/auht-selector";
import Card from "../../ui/Card";
import {useTranslation} from "react-i18next";
import InputRadio from "../../ui/InputRadio";
import InputSwitch from "../../ui/InputSwitch";
import {
    checkConnectMessageChannelsThunk,
    setDefaultMessageChanelThunk,
    toggleEnabledMessageChannelThunk
} from "../../../redux/message-channels-thunk";
import Loader from "../../Loader";
import IconQuestion from "../../../picture/icon/IconQuestion";
import {HELP_ARTICLE_CONSTANTS_IDS} from "../../../redux/helpl-aricle/help-article-constants";
import {helpArticleGetByIdToggleModalThunk} from "../../../redux/helpl-aricle/help-article-thunk";

const ICONS_MESSAGE = {
    telegram: IconTelegram,
    viber: IconViber,
    whatsapp: IconWA,
}
const styleIcon = {
    marginRight: '8px'
}
const styleQr = {height: "auto", maxWidth: "55px", width: "55px"}

const MessageChannels = () => {
    const message_channels = useSelector(messageChannelsSelected);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const connected_channel = useMemo(() => {
        const fullList = message_channels?.data?.full_message_channel_list || {};
        const connectedList = message_channels?.data?.connected_message_channel_list || {};

        let result = {};
        if (Object.keys(fullList).length && Object.keys(connectedList).length) {
            result = filterObjectByKeys(fullList, Object.keys(connectedList));
        }
        return result;
    }, [message_channels]);

    const un_connected_channel = useMemo(() => {
        const fullList = message_channels?.data?.full_message_channel_list || {};
        const connectedList = message_channels?.data?.connected_message_channel_list || {};

        let result = {};
        if (Object.keys(fullList).length && Object.keys(connectedList).length) {
            result = unFilterObjectByKeys(fullList, Object.keys(connectedList));
        } else if (Object.keys(fullList).length) {
            result = fullList;
        }
        return result;
    }, [message_channels]);

    const onSwitchMessageDefault = (key) => e => {
        dispatch(setDefaultMessageChanelThunk({default: key}))
    }

    const handlerCheckRegisterBot = (message_channel) => () => {
        dispatch(checkConnectMessageChannelsThunk(message_channel));
    }

    const toggleEnabledMessage = (key) => e => {
        let enabled = Number(e.target.checked);

        dispatch(toggleEnabledMessageChannelThunk({
            default: key, enabled: enabled
        }))
    }
    const handlerHelpArticle = (e) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(helpArticleGetByIdToggleModalThunk(HELP_ARTICLE_CONSTANTS_IDS.connect_to_chant_bot));
    }
    return message_channels.isLoading && <Loader/>
    return (
        <div className={'position-relative'}>
            {(!!Object.keys(connected_channel).length || !!Object.keys(un_connected_channel).length) &&
                <div className={'connected-channel-wrap form-group'}>
                    <Card isCollapse={true} label={<>
                        {t("chat_bot_title")}
                        <IconQuestion onClick={handlerHelpArticle} style={{marginLeft: '5px'}}/>

                    </>} className={'cabinet-connected-channel'}>
                        <ul className={'list-none'}>
                            {Object.keys(connected_channel).map(key => {
                                const isDefault = connected_channel[key].default;
                                const Icon = ICONS_MESSAGE.hasOwnProperty(key) ? ICONS_MESSAGE[key] : null
                                const name = connected_channel[key].name;
                                const isEnabled = connected_channel[key].enabled;
                                return (
                                    <li className={`item d-flex align-items-center justify-between  ${isDefault ? 'default' : ' '}`}>
                                        <InputRadio onChange={onSwitchMessageDefault(key)} checked={isDefault} id={key}
                                                    disabled={isEnabled}
                                                    label={
                                                        <div className={'d-flex align-items-center'}>   {Icon ?
                                                            <Icon style={styleIcon}/> : ''}
                                                            {name}</div>
                                                    }/>
                                        <InputSwitch onChange={toggleEnabledMessage(key)} disabled={isDefault}
                                                     checked={isEnabled}
                                                     label={t("off_on_messenger_title")}/>

                                    </li>)

                            })}
                            {Object.keys(un_connected_channel).map(key => {
                                const Icon = ICONS_MESSAGE.hasOwnProperty(key) ? ICONS_MESSAGE[key] : null
                                const name = un_connected_channel[key].name;
                                const link = un_connected_channel[key].link;
                                return (
                                    <li className={`item d-flex justify-between `}>
                                        <div className="d-flex align-items-center">
                                            {Icon ? <Icon style={styleIcon}/> : name ? name : ''}
                                            <a href={link} className="d-flex" target={'_blank'}>
                                                <QRCode
                                                    size={55}
                                                    style={styleQr}
                                                    value={link}
                                                    viewBox={`0 0 55 55 `}
                                                />
                                            </a>
                                        </div>

                                        <spna className="resend-action cursor-pointer"
                                              onClick={handlerCheckRegisterBot(key)}> {t("check_registration_title")}
                                        </spna>
                                        {/*<img src={BotUrl} alt=""/>*/}
                                    </li>)

                            })}
                        </ul>
                    </Card>
                </div>}


        </div>
    );
};

export default MessageChannels;